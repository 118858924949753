<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'inscription' + index"
      >
        {{ signa(item.id) }}
        <b-row>
          <b-col>
            <b-link
              v-if="names[item.id] != undefined"
              :to="{
                name: 'inscription',
                params: { id: item.id }
              }"
            >
              {{ names[item.id] }}
            </b-link>
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'pages.inscriptions.delete'"
              @click="removeInscription(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <base-auto-complete
      :graph-query="completions"
      :placeholder="$t('pages.inscriptions.select')"
      :map="nameMap"
      @select="addInscription"
    >
      <!-- What to show when we have no results -->
      <template v-slot:noResult>
        <span>{{ $t("components.baseList.noResult") }}</span>
      </template>
      <!-- Template for each element in the auto complete -->
      <template v-slot:option="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
      <!-- Template for a selected element in the auto complete -->
      <template v-slot:singleLabel="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
    </base-auto-complete>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "InscriptionsSelector",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      names: {},
      completions: gql`
        query searchBySignum($search: String!) {
          completions: findBySignumSearch(search: $search) {
            id
            signumInscriptions {
              canonical
              signum {
                signum1 {
                  signum1
                }
                signum2
              }
            }
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    nameMap(element) {
      return {
        name: this.sortSigna(element.signumInscriptions),
        ...element
      };
    },
    sortSigna(signumInscriptions) {
      let name, extra = [];
      signumInscriptions.forEach(si => {
        si.canonical
          ? name = si.signum.signum1.signum1 + " " + si.signum.signum2
          : extra.push(si.signum.signum1.signum1 + " " + si.signum.signum2)
      });
      return extra.length > 0 ? name + " (" + extra.sort((a, b) => a.localeCompare(b,"sv")).join(", ") + ")" : name
    },
    addInscription(element) {
      this.content.push({ id: element.id });
      this.$set(this.names, element.id, element.name);
      this.$emit("change");
    },
    removeInscription(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    signa(id) {
      if (this.names[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query inscription($id: ID) {
              inscription: inscription(id: $id) {
                id
                signumInscriptions {
                  canonical
                  signum {
                    signum1 {
                      signum1
                    }
                    signum2
                  }
                }
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          this.$set(
            this.names,
            result.data.inscription.id,
            this.sortSigna(result.data.inscription.signumInscriptions)
          );
        });
    }
  }
};
</script>
